<template>
    <div 
        class="file_card_wrap w-full h-full"
        :class="isCutted && 'cutted_file'">
        <a-checkbox 
            :checked="isChecked"
            class="file_select" 
            @change="changeSelectFile" />
        <div 
            v-if="file.is_image"
            :ref="`file_${file.id}`"
            class="file_card w-full h-full">
            <a 
                :href="file.is_image && file.path"
                class="file_image_wrapper"
                :class="file.is_image && 'lightgallery_wrap'">
                <img 
                    :data-src="file.is_image ? file.path : fileIcon" 
                    alt=""
                    class="file_icon lazyload"
                    :class="[
                        file.is_image && 'file_image', 
                        isImageLoaded && 'file_loaded_image']"
                    @load="onImageLoaded">
            </a>
            <div class="file_name text-center truncate">
                {{ file.name }}
            </div>
            <div v-if="!isFolder && file.attachment_date" class="attachment_date text-center truncate">
                <a-icon type="cloud-upload" class="truncate"/> {{ attachmentDate }}
            </div>
            <div
                v-if="file.is_dynamic" 
                class="dynamic_icon_wrap">
                <i class="fi fi-rr-engine-warning"></i>
            </div>
        </div>
        <template v-else>
            <div 
                v-if="isFolder"
                :ref="`file_${file.id}`"
                class="file_card w-full h-full"
                @click="isFolder && setCurrentSource(file.id)">
                <div class="file_image_wrapper">
                    <img 
                        :data-src="file.is_image ? file.path : fileIcon" 
                        alt=""
                        class="file_icon lazyload"
                        :class="[
                            file.is_image && 'file_image', 
                            isImageLoaded && 'file_loaded_image']"
                        @load="onImageLoaded">
                </div>
                <div class="file_name font-light text-center truncate">
                    {{ file.name }}
                </div>
                <div
                    v-if="file.is_dynamic" 
                    class="dynamic_icon_wrap">
                    <i class="fi fi-rr-engine-warning"></i>
                </div>
            </div>
            <div 
                v-else
                :ref="`file_${file.id}`"
                class="file_card w-full h-full"
                @click="fileOpenSwitch(file)">
                <div class="file_image_wrapper">
                    <img 
                        :data-src="file.is_image ? file.path : fileIcon" 
                        alt=""
                        class="file_icon lazyload"
                        :class="[
                            file.is_image && 'file_image', 
                            isImageLoaded && 'file_loaded_image']"
                        @load="onImageLoaded">
                </div>
                <div class="file_name font-light text-center truncate">
                    {{ file.name }}
                </div>
                <div
                    v-if="file.is_dynamic" 
                    class="dynamic_icon_wrap">
                    <i class="fi fi-rr-engine-warning"></i>
                </div>
            </div>
        </template>
        
        <div 
            v-if="!isFolder"
            class="file_dropdown_wrap">
            <!-- <FileDropdown :file="file"/> -->
        </div>
    </div>
</template>
<script>
import { filesFormat } from '@/utils'
import fileSourcesProps from '../../mixins/fileSourcesProps'
export default {
    name: 'FileCard',
    mixins: [fileSourcesProps],
    props: {
        file: {
            type: Object,
            required: true
        },
        setCurrentSource: {
            type: Function,
            default: () => {}
        },
        selectedFiles: {
            type: Object,
            default: () => {}
        },
        cuttedFiles: {
            type: Object,
            default: () => {}
        },
        fileOpenSwitch: {
            type: Function,
            default: () => {}
        },
        oneUpload: {
            type: Boolean,
            default: false
        },
        mobileApp: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isImageLoaded: false
        }
    },
    computed: {
        attachmentDate() {
            return this.$moment(this.file.attachment_date).format('DD.MM.YYYY HH:mm')
        },
        isFolder() {
            return this.file.obj_type === 'folder'
        },
        fileIcon() {
            if(this.isFolder)
                return require(`@/assets/images/files/folder.svg`)

            const extension = filesFormat.find(format => format === this.file.extension)
            if(extension)
                return require(`@/assets/images/files/${extension}.svg`)
            else
                return require(`@/assets/images/files/file.svg`)
        },
        isChecked() {
            return this.selectedFiles.list.includes(this.file)
        },
        isCutted() {
            return this.cuttedFiles?.list?.includes(this.file)
        },
        isMobile() {
            return this.$store.state.isMobile
        }
    },
    created() {
        if(this.file.is_image)
            this.initLightbox()
    },
    methods: {
        initLightbox() {
            this.$nextTick(() => {
                const lightboxWrap = this.$refs[`file_${this.file.id}`],
                    lightbox = lightboxWrap.querySelector('.lightgallery_wrap')
                if(lightbox)
                    lightGallery(lightboxWrap, {
                        selector: ".lightgallery_wrap",
                        thumbnail: true,
                        rotateLeft: true,
                        rotateRight: true,
                        flipHorizontal: false,
                        flipVertical: false,
                        actualSize: this.isMobile ? false : true,
                        fullScreen: true,
                        animateThumb: true,
                        showThumbByDefault: true,
                        download: this.mobileApp ? false : true,
                        speed: 300
                    })
            })
        },
        onImageLoaded() {
            this.isImageLoaded = true
        },
        changeSelectFile(event) {
            this.selectedFiles.from = this.sourceId
            const isChecked = event.target.checked
            if(isChecked)
                this.selectedFiles.list.push(this.file)
            else if(this.selectedFiles.list.includes(this.file)) {
                const fileIndex = this.selectedFiles.list.findIndex(file => file.id === this.file.id)
                this.selectedFiles.list.splice(fileIndex, 1)
                if(this.cuttedFiles?.list?.includes(this.file)) {
                    const fileIndex = this.cuttedFiles.list.findIndex(file => file.id === this.file.id)
                    this.cuttedFiles.list.splice(fileIndex, 1)
                }
            }
        }
    },
}
</script>

<style scoped lang="scss">   
.attachment_date {
    opacity: 0.6;
    font-size: 0.8rem;
} 
.cutted_file {
    opacity: 0.5;
}
.file_card_wrap {
    position: relative;
    width: 170px;
    height: 200px;
    .file_select {
        position: absolute;
        top: 8px;
        left: 8px;
    }
}
.file_card {

    border-radius: var(--borderRadius);
    
    transition: background-color 0.2s ease;
    &:hover {
        background-color: #f0f3f7;
        .file_dropdown_wrap {
            display: block;
        }
    }
    .file_dropdown_wrap {
        position: absolute;
        top: 5%;
        right: 5%;
        display: none;
    }
    cursor: pointer;
    .file_image_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 0 15px;

        height: 150px;
        .file_icon {
            width: 100%;
            max-height: 100px;
            
            object-fit: contain;
        }
        .file_image {
            border: 1px solid var(--borderColor);
            border-radius: 4px;
            opacity: 0;
        }
        .file_loaded_image {
            opacity: 1;
        }
    }
    .file_name {
        margin-top: 10px;
        padding: 0 15px;
    }
    .dynamic_icon_wrap {
        position: absolute;
        top: 8px;
        right: 8px;

        display: flex;
        justify-content: center;
        align-items: center;
        
        font-size: 1.2rem;
        line-height: 1;
    }
}
</style>

<style lang="scss">
.file_card_wrap {
    .file_select {
        .ant-checkbox:hover::after {
            border-radius: 100%;
        }
        .ant-checkbox-inner::after {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: 
                translate(-50%, -50%) 
                rotate(45deg);
        }
        .ant-checkbox-inner {
            width: 24px;
            height: 24px;
            border-radius: 100%;
        }
    }
}
</style>