var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"file_card_wrap w-full h-full",class:_vm.isCutted && 'cutted_file'},[_c('a-checkbox',{staticClass:"file_select",attrs:{"checked":_vm.isChecked},on:{"change":_vm.changeSelectFile}}),(_vm.file.is_image)?_c('div',{ref:`file_${_vm.file.id}`,staticClass:"file_card w-full h-full"},[_c('a',{staticClass:"file_image_wrapper",class:_vm.file.is_image && 'lightgallery_wrap',attrs:{"href":_vm.file.is_image && _vm.file.path}},[_c('img',{staticClass:"file_icon lazyload",class:[
                    _vm.file.is_image && 'file_image', 
                    _vm.isImageLoaded && 'file_loaded_image'],attrs:{"data-src":_vm.file.is_image ? _vm.file.path : _vm.fileIcon,"alt":""},on:{"load":_vm.onImageLoaded}})]),_c('div',{staticClass:"file_name text-center truncate"},[_vm._v(" "+_vm._s(_vm.file.name)+" ")]),(!_vm.isFolder && _vm.file.attachment_date)?_c('div',{staticClass:"attachment_date text-center truncate"},[_c('a-icon',{staticClass:"truncate",attrs:{"type":"cloud-upload"}}),_vm._v(" "+_vm._s(_vm.attachmentDate)+" ")],1):_vm._e(),(_vm.file.is_dynamic)?_c('div',{staticClass:"dynamic_icon_wrap"},[_c('i',{staticClass:"fi fi-rr-engine-warning"})]):_vm._e()]):[(_vm.isFolder)?_c('div',{ref:`file_${_vm.file.id}`,staticClass:"file_card w-full h-full",on:{"click":function($event){_vm.isFolder && _vm.setCurrentSource(_vm.file.id)}}},[_c('div',{staticClass:"file_image_wrapper"},[_c('img',{staticClass:"file_icon lazyload",class:[
                        _vm.file.is_image && 'file_image', 
                        _vm.isImageLoaded && 'file_loaded_image'],attrs:{"data-src":_vm.file.is_image ? _vm.file.path : _vm.fileIcon,"alt":""},on:{"load":_vm.onImageLoaded}})]),_c('div',{staticClass:"file_name font-light text-center truncate"},[_vm._v(" "+_vm._s(_vm.file.name)+" ")]),(_vm.file.is_dynamic)?_c('div',{staticClass:"dynamic_icon_wrap"},[_c('i',{staticClass:"fi fi-rr-engine-warning"})]):_vm._e()]):_c('div',{ref:`file_${_vm.file.id}`,staticClass:"file_card w-full h-full",on:{"click":function($event){return _vm.fileOpenSwitch(_vm.file)}}},[_c('div',{staticClass:"file_image_wrapper"},[_c('img',{staticClass:"file_icon lazyload",class:[
                        _vm.file.is_image && 'file_image', 
                        _vm.isImageLoaded && 'file_loaded_image'],attrs:{"data-src":_vm.file.is_image ? _vm.file.path : _vm.fileIcon,"alt":""},on:{"load":_vm.onImageLoaded}})]),_c('div',{staticClass:"file_name font-light text-center truncate"},[_vm._v(" "+_vm._s(_vm.file.name)+" ")]),(_vm.file.is_dynamic)?_c('div',{staticClass:"dynamic_icon_wrap"},[_c('i',{staticClass:"fi fi-rr-engine-warning"})]):_vm._e()])],(!_vm.isFolder)?_c('div',{staticClass:"file_dropdown_wrap"}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }